<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="users" :actions="actions" :paginate="paginate" />
        </div>
      </div>
    </div>
    <toast position="se"></toast>
  </div>
</template>
<script>
  import { MessageBox, Message } from 'element-ui'
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import userSchemas from './user-schemas'
  import dtHelper from 'src/helpers/datatable'
  import { Toast } from 'vuex-toast'
  import { mapActions } from 'vuex'
  import { ADD_TOAST_MESSAGE } from 'vuex-toast'
  import util from '../../../../helpers/util'

  export default{
    components: {
      MyTable,
      MyFilter,
      Toast
    },
    computed: {
      users() {
        let rows = this.$store.state.users.data || [];
        return rows;
      },
      paginate() {
        let item = this.$store.state.users || {};
        item.type = 'users';
        return item;
      }
    },
    data () {
      let actions = [
        {
          type: 'primary',
          icon: 'nc-icon nc-ruler-pencil',
          callback: this.edit
        }
      ];
      if (util.getCookie('role') && util.getCookie('role') == 1) {
        actions.push({
          type: 'danger',
          icon: 'nc-icon nc-simple-remove',
          callback: this.remove
        });
      }
      return {
        filterOutput: this.$store.state.currentFilterUsers || [],
        columnDefs: dtHelper.buildColumDefs(userSchemas),
        actions: actions,
        filter: {},
        rules: dtHelper.buildRules(userSchemas),
        prevRoute: ''
      }
    },
    mounted() {
      window.AP = this;
      if (this.filterOutput && this.filterOutput.length) {
        let filterString = dtHelper.buildQueryString(this.filterOutput);
        this.$store.dispatch('fetchUsers', {
          filterString: filterString
        });
      } else {
        this.$store.dispatch('fetchUsers');
      }
      this.$store.dispatch('setPageTitle', 'Quản lý người dùng');
      this.$store.dispatch('setFilterTags', []);
      this.$store.dispatch('setCurrentFilterOrders', []);
    },
    methods: {
      updateFilter(filterOutput) {
        this.$store.dispatch('setCurrentFilterUsers', filterOutput);
        this.filterOutput = filterOutput;
        let filterString = dtHelper.buildQueryString(filterOutput);
        this.$store.dispatch('fetchUsers', {
          filterString: filterString
        });
      },
      edit(index, row) {
        this.$router.push('/users/' + row.id);
      },
      ...mapActions({
        addToast: ADD_TOAST_MESSAGE
      }),
      toast(text, type) {
        this.addToast({
          text,
          type: type,
          dismissAfter: 3000
        })
      },
      remove(index, row) {
        MessageBox.confirm('Xóa người dùng?', 'Warning', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning',
          center: true
        }).then(() => {
          this.$store.dispatch('removeUser', row.id).then(res => {
            if (res.success) {
              this.toast(res.message, 'success');
              this.$store.dispatch('fetchUsers');
            } else {
              if (res.errors) {
                this.toast(res.errors, "danger");
              } else {
                this.toast("Có lỗi xảy ra, xin vui lòng thử lại", "danger");
              }
            }
          });
        }, error => {
          console.error(error);
        });
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
