export default [
  {
    prop: 'id',
    label: 'Mã số',
    minWidth: 80,
    type: 'id'
  },
  {
    prop: 'name',
    label: 'Họ tên',
    minWidth: 180,
    type: 'text'
  },
  {
    prop: 'email',
    label: 'Email',
    minWidth: 180,
    type: 'text'
  },
  {
    prop: 'phone',
    label: 'SĐT',
    minWidth: 180,
    type: 'text'
  },
  {
    prop: 'bib',
    label: 'BIB',
    minWidth: 180,
    type: 'text'
  }
]
