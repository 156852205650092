import { render, staticRenderFns } from "./All-Users.vue?vue&type=template&id=faf5148e&"
import script from "./All-Users.vue?vue&type=script&lang=js&"
export * from "./All-Users.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/truyen/workspace/dlvn/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('faf5148e')) {
      api.createRecord('faf5148e', component.options)
    } else {
      api.reload('faf5148e', component.options)
    }
    module.hot.accept("./All-Users.vue?vue&type=template&id=faf5148e&", function () {
      api.rerender('faf5148e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Dashboard/Views/Users/All-Users.vue"
export default component.exports